html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html,
body,
#root {
  height: 100%;
}

::-webkit-scrollbar {
  background: transparent;
  width: 0rem;
  height: 0rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
}

#root {
  width: 100%;
  height: 100%;
}

.fade-enter {
  opacity: 0.8;
}

.fade-enter-active {
  transition: all 0.1s ease-in;
  opacity: 1;
}

.fade-exit-active,
.fade-exit-done {
  opacity: 0;
}

/* Slider */
.slick-list {
  max-width: 80%;
  margin-left: 9rem;
  margin-top: 3rem;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.slick-next,
.slick-prev {
  right: 3.5rem;
  background: #000;
  border-radius: 50%;
}
.slick-prev {
  left: 3.5rem;
  background: #000;
  border-radius: 50%;
}
.slick-next:before,
.slick-prev:before {
  color: #fff;
  font-size: 50px;
  opacity: 1;
  margin-left: -4px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: #000;
  color: #fff;
}

.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  background: transparent;
}

@media screen and (min-width: 1100px) {
  .slick-list {
    margin-left: 10.5rem;
  }
}

/* Can be removed if current active wbeex slider works for High res */
.mySlides {
  display: none;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 24px;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  z-index: 999;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  top: 35%;
  z-index: 1;
  border-radius: 50% !important;
}
.arrow-btn:hover {
  background: transparent !important;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 2px #3f3f4463;
}
.next {
  float: right;
  margin-right: 20px !important;
}
.prev {
  float: left;
  margin-left: 30px !important;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
/* Till here it can be removed */

.slick-dots {
  bottom: -80px;
}
